<template>
  <div class="file-upload-container">
    <div
      class="upload-area"
      :class="{ dragging: isDragging }"
      @drop.prevent="handleDrop"
      @dragover.prevent="isDragging = true"
      @dragleave.prevent="isDragging = false"
    >
      <input
        ref="fileInput"
        type="file"
        multiple
        accept=".pdf,.zip,.rar,.png,.jpeg,.jpg"
        class="hidden"
        @change="handleFileSelect"
      >

      <div
        v-if="!isDragging"
        class="upload-prompt"
        style="display: flex; align-items: center;"
      >
        <bg-button
          variant="ghost"
          size="sm"
          @click="$refs.fileInput.click()"
        >
          <bg-icon
            size="md"
            name="upload"
            color="blue-normal"
          />
        </bg-button>
        <bg-text>
          Clique no ícone ou arraste o arquivo (.pdf, .png, .jpeg/jpg, .zip, .rar) para validar sua posição
        </bg-text>
      </div>

      <div
        v-else
        class="drop-prompt"
      >
        Solte os arquivos aqui
      </div>
    </div>

    <div
      v-if="files.length"
      class="file-list"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="file-item"
      >
        <span class="file-name">{{ file.name }}</span>
        <button
          class="remove-button"
          @click="removeFile(index)"
        >
          Remover
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BgIcon, BgButton, BgText } from '@transfeeradev/bridge';

export default {
  name: 'file-uploader',
  components: {
    BgIcon,
    BgButton,
    BgText
  },
  emits: ['error', 'files-changed'],
  data() {
    return {
      files: [],
      isDragging: false
    };
  },
  methods: {
    handleDrop(e) {
      this.isDragging = false;
      const droppedFiles = Array.from(e.dataTransfer.files);
      this.addFiles(droppedFiles);
    },
    handleFileSelect(e) {
      const selectedFiles = Array.from(e.target.files);
      this.addFiles(selectedFiles);
    },
    addFiles(newFiles) {
      const allowedFileContentTypes = [
        'application/pdf',
        'application/zip',
        'application/x-zip-compressed',
        'application/zip-compressed',
        'application/x-rar-compressed',
        'application/x-compressed', // rar file
        'application/x-rar',
        'application/vnd.rar',
        'image/png',
        'image/jpeg',
        'image/jpg'
      ];

      const validFiles = newFiles.filter(file => allowedFileContentTypes.includes(file.type));

      if (validFiles.length !== newFiles.length) {
        this.$toaster.error('Somente arquivos PDF, ZIP, RAR, PNG ou JPEG são permitidos');
      }

      const maxSizeInBytes = 15 * 1024 * 1024; // 15 MB
      const oversizedFiles = validFiles.filter(file => file.size > maxSizeInBytes);

      if (oversizedFiles.length > 0) {
        this.$toaster.error('O arquivo deve ter no máximo 15 MB');
        return;
      }

      if (this.files.length + validFiles.length > 1) {
        this.$toaster.error('Não é possível adicionar mais de um arquivo');
        return;
      }

      this.files.push(...validFiles);
      this.$emit('files-changed', this.files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$emit('files-changed', this.files);
    }
  }
};
</script>

<style scoped>
.file-upload-container {
  width: 100%;
}

.upload-area {
  border: 2px dashed #ccc;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-area.dragging {
  border-color: #2196f3;
  background: #e3f2fd;
}

.hidden {
  display: none;
}

.upload-button {
  background: #2196f3;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-right: 1rem;
}

.file-list {
  margin-top: 1rem;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.remove-button {
  color: #f44336;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
