<template>
  <bg-badge
    :size="size"
    :color="badgeColor"
  >
    <bg-text :color="textColor">
      {{ statusText }}
    </bg-text>
  </bg-badge>
</template>

<script setup>
import { computed } from 'vue';
import { BgText, BgBadge } from '@transfeeradev/bridge';
import analysisStatus from '@/apps/contestations/infractions/constants/analysisStatus';

const props = defineProps({
  infraction: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'sm'
  }
});

const analysisStatusColor = computed(() => ({
  pending: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light' },
  accepted: { textColor: 'green-semi-dark', badgeColor: 'green-light' },
  rejected: { textColor: 'blue-semi-dark', badgeColor: 'blue-light' },
  delayed: { textColor: 'red-semi-dark', badgeColor: 'red-light' },
  canceled: { textColor: 'grey-semi-dark', badgeColor: 'grey-light' }
}));

const badgeColor = computed(() => {
  return analysisStatusColor.value[props.infraction.analysis_status]?.badgeColor || 'grey-light';
});

const textColor = computed(() => {
  return analysisStatusColor.value[props.infraction.analysis_status]?.textColor || 'grey-semi-dark';
});

const statusText = computed(() => {
  return analysisStatus[props.infraction.analysis_status] || props.infraction.analysis_status || '-';
});
</script>
