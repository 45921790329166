<template>
  <tr-button
    dropdown="true"
    :label="selectedFilter"
    text-variant="body"
    variant="text"
  >
    <tr-dropdown-item
      key="all"
      :on-item-click="search.bind(undefined, undefined, 'Tipo de validação')"
    >
      Tipo de validação
    </tr-dropdown-item>
    <tr-dropdown-item
      v-for="(statusName, statusKey) in VALIDATION_TYPES"
      :key="statusKey"
      :on-item-click="search.bind(undefined, statusKey, statusName)"
    >
      {{ statusName }}
    </tr-dropdown-item>
  </tr-button>
</template>

<script>
import { contacerta } from '@transfeeradev/api-enums';
const VALIDATION_TYPES = {
  [contacerta.type.MICRO_DEPOSIT]: 'Microdepósito',
  [contacerta.type.BASIC]: 'Básica'
};
export default {
  name: 'validation-history-types-dropdown-filter-container',
  emits: ['search'],
  data: () => ({
    VALIDATION_TYPES,
    selectedFilter: 'Tipo de validação'
  }),
  methods: {
    search(key, name) {
      this.selectedFilter = name;
      this.$emit('search', key);
    }
  }
};
</script>
