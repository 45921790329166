<template>
  <status-badge
    :text="badge.text"
    :type="badge.type"
    :icon="badge.icon"
  />
</template>

<script setup>
import { CardReceivableStatus } from '@/apps/payin/card-receivables/services/cardReceivables';
import StatusBadge from '@/commons/components/presentational/StatusBadge.vue';
import { computed } from 'vue';

const props = defineProps({
  status: {
    type: String,
    validator: value => Object.values(CardReceivableStatus).includes(value),
    required: true
  }
});

const badge = computed(() => {
  return {
    [CardReceivableStatus.SCHEDULED]: {
      text: 'A receber',
      type: 'yellow',
      icon: 'calendar_today'
    },
    [CardReceivableStatus.RECEIVED]: {
      text: 'Recebido',
      type: 'green',
      icon: 'check-price'
    },
    [CardReceivableStatus.CANCELED]: {
      text: 'Cancelado',
      type: 'grey',
      icon: 'cancel'
    },
    [CardReceivableStatus.REFUNDED]: {
      text: 'Devolvido',
      type: 'blue',
      icon: 'cancel'
    },
    [CardReceivableStatus.CHARGEBACK]: {
      text: 'Chargeback',
      type: 'red',
      icon: 'cancel'
    }
  }[props.status];
});
</script>

<style lang="scss" scoped>

</style>
