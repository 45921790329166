<template>
  <tr-loading
    v-if="isLoading"
    classes="center-h-and-v"
  />
  <infraction-details
    v-if="!isLoading"
    :infraction-data="infraction"
    :is-analysis-form-visible="isAnalysisFormVisible"
    @back="onBack"
    @show-analysis-form="showAnalysisForm"
  />
  <infraction-analysis-form
    v-if="isAnalysisFormVisible"
    :infraction-data="infraction"
    @show-analysis-form="showAnalysisForm"
    @back="onBack"
    @analysis-submitted="$emit('analysis-submitted')"
  />
</template>

<script>
import InfractionDetails from '@/apps/contestations/infractions/presentational/InfractionDetails.vue';
import InfractionAnalysisForm from '@/apps/contestations/infractions/presentational/InfractionAnalysisForm.vue';
import { getInfraction } from '@/apps/contestations/infractions/services/infractions';

export default {
  name: 'infraction-details-container',
  components: {
    InfractionDetails,
    InfractionAnalysisForm
  },
  emits: ['back', 'analysis-submitted'],
  data() {
    return {
      isLoading: true,
      isAnalysisFormVisible: false,
      infraction: {}
    };
  },
  created() {
    this.fetchInfraction();
  },
  methods: {
    async fetchInfraction() {
      try {
        this.isLoading = true;
        this.infraction = await getInfraction(this.$route.params.id);
      } catch (error) {
        this.$toaster.error('Não foi possível carregar os detalhes da infração.');
      } finally {
        this.isLoading = false;
      }
    },
    onBack() {
      this.$emit('back');
    },
    showAnalysisForm(boolean) {
      this.isAnalysisFormVisible = boolean;
    }
  }
};
</script>
