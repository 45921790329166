<template>
  <tr-table>
    <tr-thead>
      <tr-tr>
        <tr-th width="10%">
          Data
        </tr-th>
        <tr-th width="15%">
          Tipo de validação
        </tr-th>
        <tr-th width="26%">
          Nome
        </tr-th>
        <tr-th width="22%">
          Pix ou Agência/Conta
        </tr-th>
        <tr-th width="18%">
          Status
        </tr-th>
        <tr-th width="16%" />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="validation in validations"
        :key="validation.id"
        :on-item-click="showValidationResultModal.bind(undefined, validation)"
      >
        <tr-td>
          <tr-typography>
            {{ formatDate(validation.created_at) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography>
            <span class="inline-block">{{ getType(validation) }}</span>
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography class="margin-right-10">
            <img
              class="ic--small"
              :src="getBankImage(validation)"
              :alt="validation.bank?.name"
            >
          </tr-typography>
          <tr-typography v-tooltip.top-center="getName(validation.data)">
            {{ breakBiggerWord(getName(validation.data), 40) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <tr-typography v-tooltip.top-center="dbaAccountRepr(validation.data || {})">
            {{ breakBiggerWord(dbaAccountRepr(validation.data || {}), 30) }}
          </tr-typography>
        </tr-td>
        <tr-td>
          <validation-status-pill
            :status="getStatus(validation)"
          />
        </tr-td>
        <tr-td>
          <validation-receipt-container
            v-if="validation.type === type.MICRO_DEPOSIT"
            :validation-data="validation"
          />
          <span
            v-else
            class="block text-center"
          >---</span>
        </tr-td>
      </tr-tr>
    </tr-tbody>
    <validation-result-fullscreen-modal
      ref="validationResultModal"
      :validation-id="clickedValidation.id"
    />
  </tr-table>
</template>

<script>
import { contacerta } from '@transfeeradev/api-enums';
import config from '@/config';
import formatDate from '@/commons/formatters/formatDate';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import capitalize from '@/commons/formatters/capitalize';
import dbaAccountRepr from '@/commons/formatters/dbaAccountRepr';
import ValidationResultFullscreenModal from '@/apps/contacerta/validations/pages/ValidationResultFullscreenModal';
import ValidationReceiptContainer from '../container/ValidationReceiptContainer';
import ValidationStatusPill from './ValidationStatusPill';
import { isPix } from '@transfeeradev/api-services/TransferPixService';
import VALIDATION_STATUS from '@/apps/contacerta/validations/constants/validationStatus';

export default {
  name: 'validation-history-table',
  components: {
    ValidationResultFullscreenModal,
    ValidationReceiptContainer,
    ValidationStatusPill
  },
  props: {
    validations: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    type: contacerta.type,
    clickedValidation: {}
  }),
  methods: {
    breakBiggerWord,
    formatDate,
    capitalize,
    dbaAccountRepr,
    getBankImage(validation) {
      if (isPix(validation?.data)) {
        return `${config.url.cdn}/banks/pix-circle.svg`;
      }

      if (validation.bank?.image) {
        return validation.bank.image;
      }

      return `${config.url.cdn}/banks/default_bank.svg`;
    },
    getStatus(validation) {
      if (validation.valid) {
        return VALIDATION_STATUS.VALIDATED;
      }
      if (!validation.valid && validation.errors?.length) {
        return VALIDATION_STATUS.INVALID;
      }
      return VALIDATION_STATUS.VALIDATING;
    },
    getName(validationData) {
      return validationData?.name || '---';
    },
    getType(validation) {
      if (validation.type === contacerta.type.MICRO_DEPOSIT) {
        return 'Microdepósito';
      }
      return 'Básica';
    },
    showValidationResultModal(clickedValidation) {
      this.clickedValidation = clickedValidation;
      this.$refs.validationResultModal.open();
    },
    hasReceiptToDownload(transfer) {
      return transfer.bank_receipt_url || transfer.receipt_url;
    }
  }
};
</script>
