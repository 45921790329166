<template>
  <bg-badge
    :size="size"
    :color="getTimeColor(daysSinceInfraction(infractionDate)).badgeColor"
  >
    <bg-text :color="getTimeColor(daysSinceInfraction(infractionDate)).textColor">
      {{ daysSinceInfraction(infractionDate) }}
    </bg-text>
  </bg-badge>
</template>

<script setup>
import moment from '@transfeeradev/moment';
import { BgText, BgBadge } from '@transfeeradev/bridge';
import { defineProps } from 'vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  infractionDate: {
    type: String,
    required: true
  },
  size: {
    type: String,
    default: 'sm'
  }
});

const daysSinceInfraction = infractionDateString => {
  const infractionDate = moment(infractionDateString);
  const infractionDueDate = infractionDate.businessAdd(3, 'days');
  const remainingTime = moment.duration(infractionDueDate.diff(moment()));
  const hours = Math.floor(remainingTime.asHours());
  const minutes = remainingTime.minutes();
  return hours <= 0 ? '-' : `${hours}h${minutes}m`;
};

const timeBadgeColor = {
  yellow: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light' },
  red: { textColor: 'red-semi-dark', badgeColor: 'red-light' },
  gray: { textColor: 'grey-semi-dark', badgeColor: 'grey-light' }
};

const getTimeColor = time => {
  if (time === '-') {
    return timeBadgeColor.gray;
  }
  const hours = parseInt(time.split('h')[0], 10);
  return hours <= 24 ? timeBadgeColor.red : timeBadgeColor.yellow;
};
</script>