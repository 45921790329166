<template>
  <page title="Análise da Infração">
    <infraction-details-container
      @back="onBack"
      @analysis-submitted="$emit('analysis-submitted')"
    />
  </page>
</template>

<script>
import Page from '@/commons/components/presentational/Page.vue';
import InfractionDetailsContainer from '@/apps/contestations/infractions/container/InfractionDetailsContainer.vue';

export default {
  name: 'infraction-details-page',
  components: {
    InfractionDetailsContainer,
    Page
  },
  emits: ['analysis-submitted'],
  methods: {
    onBack() {
      this.$router.push({ name: 'InfractionsPage' });
    }
  }
};
</script>
