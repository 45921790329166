<template>
  <div>
    <div class="row">
      <div
        class="col-lg-4"
        style="margin-bottom: 30px;"
      >
        Prazo para resposta:
        <div style="margin-top: 10px;">
          <days-since-the-infraction-pill
            :infraction-date="infractionData.infraction_date"
            size="md"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <tr-read-only-text-field
        class="col-lg-4"
        label="Nome do pagador:"
        :value="infractionData.payer_name"
      />
      <div class="col-lg-4">
        <tr-read-only-text-field label="Documento:">
          {{ formatCpfCnpj(infractionData.payer_tax_id) }}
          <copy-clipboard-button
            type="inline"
            :text-to-copy="infractionData.payer_tax_id"
          />
        </tr-read-only-text-field>
      </div>
      <tr-read-only-text-field
        class="col-lg-4"
        label="Recebida em:"
        :value="formatDateTime(infractionData.infraction_date)"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        class="col-lg-3"
        label="Valor da infração:"
        :value="centsToReal(infractionData.amount)"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Status da contestação:"
        :value="formatAnalysisStatus(infractionData.analysis_status)"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Tipo da infração:"
        :value="formatSituationType(infractionData.situation_type)"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Data da devolução:"
        :value="infractionData.refund?.refund_date ? formatDateTime(infractionData.refund?.refund_date) : '-'"
      />
    </div>
    <div class="row">
      <tr-read-only-text-field
        class="col-lg-3"
        label="Valor devolvido:"
        :value="infractionData.refund?.refunded_amount ? centsToReal(infractionData.refund?.refunded_amount) : '-'"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Parecer Transfeera:"
        :value="formatStatus(infractionData.status)"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Aprovado por:"
        :value="infractionData.user?.name || '-'"
      />
      <tr-read-only-text-field
        class="col-lg-3"
        label="Status da devolução:"
        :value="infractionData.refund?.status ? formatRefundStatus(infractionData.refund?.status) : '-'"
      />
    </div>
    <div class="row">
      <div
        class="col-lg-8"
      >
        Descrição da infração
        <textarea
          v-model="infractionData.infraction_description"
          readonly
          class="textarea"
          rows="8"
        />
      </div>
      <div class="col-lg-4">
        <div class="row vertical-offset2">
          <bg-text type="h6">
            ID da infração:
          </bg-text>
          <div style="display: inline-flex; align-items: center; gap: 4px;">
            {{ middleTruncateWithEllipsis(infractionData.id, 20) }}
            <copy-clipboard-button
              type="inline"
              :text-to-copy="infractionData.id"
            />
          </div>
        </div>
        <div class="row vertical-offset2">
          <bg-text type="h6">
            E2E Pix:
          </bg-text>
          <div style="display: inline-flex; align-items: center; gap: 4px;">
            {{ middleTruncateWithEllipsis(infractionData.transaction_id, 20) }}
            <copy-clipboard-button
              type="inline"
              :text-to-copy="infractionData.transaction_id"
            />
          </div>
        </div>
        <div class="row vertical-offset2">
          <bg-text type="h6">
            E2E Pix Devolução:
          </bg-text>
          <div style="display: inline-flex; align-items: center; gap: 4px;">
            {{ infractionData.refund?.transaction_id ? middleTruncateWithEllipsis(infractionData.refund?.transaction_id, 20) : '-' }}
            <copy-clipboard-button
              v-if="infractionData.refund?.transaction_id"
              type="inline"
              :text-to-copy="infractionData.refund?.transaction_id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <tr-footer-buttons-container class="inline-block">
    <bg-button
      v-if="!isAnalysisFormVisible"
      class="pull-left"
      variant="outline"
      @click="onBack"
    >
      Voltar
    </bg-button>
    <bg-button
      v-if="(!isAnalysisFormVisible && infractionData.analysis_status === 'pending') && !infractionData.contested_at"
      class="pull-right"
      size="md"
      @click="emitShowAnalysisForm"
    >
      Responder infração
    </bg-button>
  </tr-footer-buttons-container>
</template>

<script>
import DaysSinceTheInfractionPill from '@/apps/contestations/infractions/components/DaysSinceTheInfractionPill.vue';
import CopyClipboardButton from '@/commons/components/container/CopyClipboardButton.vue';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { formatDateTime } from '@/commons/formatters/formatDate';
import { middleTruncateWithEllipsis } from '@/commons/formatters/string';
import { centsToReal } from '@/commons/formatters/currency';
import formatCpfCnpj from '@/commons/formatters/cpfCnpj';
import infractionStatus from '@/apps/contestations/infractions/constants/infractionStatus';
import situationTypes from '@/apps/contestations/infractions/constants/situationTypes';
import analysisStatus from '@/apps/contestations/infractions/constants/analysisStatus';
import refundStatus from '@/apps/contestations/infractions/constants/refundStatus';

export default {
  name: 'infraction-details',
  components: {
    DaysSinceTheInfractionPill,
    BgText,
    BgButton,
    CopyClipboardButton
  },
  props: {
    infractionData: {
      type: Object
    },
    isAnalysisFormVisible: {
      type: Boolean
    }
  },
  emits: ['show-analysis-form', 'back'],
  setup() {
    return {
      formatDateTime,
      middleTruncateWithEllipsis,
      centsToReal,
      formatCpfCnpj
    };
  },
  data() {
    return {
      showButton: true
    };
  },
  methods: {
    emitShowAnalysisForm() {
      this.$emit('show-analysis-form', true);
    },
    formatSituationType(situationType) {
      return situationTypes[situationType];
    },
    formatStatus(status) {
      return infractionStatus[status];
    },
    formatAnalysisStatus(status) {
      return analysisStatus[status];
    },
    formatRefundStatus(status) {
      return refundStatus[status];
    },
    onBack() {
      this.$emit('back');
    }
  }
};
</script>

<style scoped>
.textarea {
  font-family: inherit;
  border: 1px solid var(--bg-grey-semi-light);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: var(--bg-grey-light);
  color: var(--bg-grey-semi-dark);
  padding: 16px;
  resize: vertical;
  width: 100%;
}
</style>
