<template>
  <tr-alert-notification
    :type="type"
    :hide-icon="hideDefaultIcon"
  >
    <div
      class="center-v validation-result-message"
    >
      <template v-if="status === VALIDATION_RESULT_STATUS.LOADING">
        <img
          class="center-v pull-left"
          src="/assets/img/icons/spinner.svg"
          alt="Icone indicando o processamento"
        >
        <div class="pull-left horizontal-offset2">
          <tr-typography
            v-if="isNewValidation"
          >
            Por favor, aguarde o retorno da validação...
          </tr-typography>
          <tr-typography
            v-else
          >
            Carregando...
          </tr-typography>
        </div>
      </template>
      <template v-if="status === VALIDATION_RESULT_STATUS.SUCCESS">
        <img
          class="center-v pull-left"
          src="/assets/img/icons/check-circle.svg"
          alt="Icone indicando que foi validado"
        >
        <div class="pull-left horizontal-offset2">
          <tr-typography>
            Os dados bancários informados estão corretos.
            <span v-if="isMicroDeposit"><br> O microdepósito foi enviado para o recebedor, acesse abaixo o comprovante:</span>
          </tr-typography>
        </div>
      </template>
      <tr-typography v-if="status === VALIDATION_RESULT_STATUS.WARNING">
        <span v-if="isNewValidation">Os dados bancários informados levarão mais tempo para serem validados, acompanhe o status dessa e outras validações em seu <router-link
          class="underlined cursor-pointer"
          :to="{ name: 'ContaCertaValidationHistoryPage' }"
        >
          histórico de validações
        </router-link>.</span>
        <span v-else>Os dados bancários informados levarão mais tempo para serem validados.</span>
      </tr-typography>
      <tr-typography v-if="status === VALIDATION_RESULT_STATUS.ERROR">
        Os dados bancários informados estão incorretos.
        <div
          v-for="(error) in errors"
          :key="error.field"
        >
          {{ error.message }} <strong>
            <span
              v-if="error.suggestions.length"
            >{{ error.suggestions.length > 1 ? `Sugestões` : `Sugestão` }}:</span> {{ getSuggestions(error) }}
          </strong>
        </div>
      </tr-typography>
    </div>
  </tr-alert-notification>
</template>

<script>
import VALIDATION_RESULT_STATUS from '@/apps/contacerta/validations/constants/validationResultStatus';

export default {
  name: 'validation-result-status',
  props: {
    status: {
      type: String,
      required: true
    },
    isMicroDeposit: {
      type: Boolean,
      required: false
    },
    isNewValidation: {
      type: Boolean,
      required: false
    },
    errors: {
      type: Array,
      required: false
    }
  },
  data() {
    return { VALIDATION_RESULT_STATUS };
  },
  computed: {
    hideDefaultIcon() {
      return this.status === VALIDATION_RESULT_STATUS.LOADING || this.status === VALIDATION_RESULT_STATUS.SUCCESS;
    },
    type() {
      switch (this.status) {
        case VALIDATION_RESULT_STATUS.WARNING:
          return 'warning';
        case VALIDATION_RESULT_STATUS.SUCCESS:
          return 'success';
        case VALIDATION_RESULT_STATUS.ERROR:
          return 'danger';
        default:
          return 'info';
      }
    }
  },
  methods: {
    getSuggestions(error) {
      return error.suggestions
        .map(({ field, value }) => {
          if (!field) {
            return value;
          }
          return `${field} deve ser ${value}`;
        })
        .join(', ');
    }
  }
};
</script>
