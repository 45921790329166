import PayinsLayout from '@/apps/payin/Layout';
import PayinsPage from '@/apps/payin/payins/pages/PayinsPage.vue';
import PaymentLinkPage from '@/apps/payin/payment-link/pages/PaymentLinkPage.vue';
import PaymentLinkListPage from '@/apps/payin/payment-link/pages/PaymentLinkListPage.vue';
import CardReceivablesListPage from '@/apps/payin/card-receivables/pages/CardReceivablesListPage.vue';

import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import settingsResource from '@/commons/resources/settings';
import { APPS } from '@/commons/constants/apps';
import { hasFeatureFlag } from '@/commons/services/featureFlags';

export default {
  path: '/recebimentos',
  component: PayinsLayout,
  name: 'Payins',
  redirect: {
    name: 'PayinsPage'
  },
  beforeEnter: () => {
    checkpointResource.create(checkpoint.PAYIN_PAGE_VIEWED);
  },
  children: [
    {
      path: 'recebiveis',
      component: PayinsPage,
      name: 'PayinsPage'
    },
    {
      path: 'links-de-pagamento',
      component: PaymentLinkListPage,
      name: 'PaymentLinkListPage'
    },
    {
      path: 'links-de-pagamento/novo',
      component: PaymentLinkPage,
      name: 'PaymentLinkPage'
    },
    {
      path: 'cartao-de-credito',
      component: CardReceivablesListPage,
      name: 'CardReceivablesListPage',
      meta: {
        beforeEnter: [featureFlagGuard],
        isAllowed() {
          return settingsResource.get().then(settings => settings.hasCardCharge);
        }
      }
    }
  ].map(route => ({
    ...route,
    meta: {
      authAs: APPS.PAYMENTS.key,
      ...route.meta
    }
  }))
};

function featureFlagGuard() {
  if (!hasFeatureFlag('card-charge')) {
    return '/';
  }
}
